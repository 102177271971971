* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body>#root>div {
  height: 100vh;
}

:root {
  --primaryColor: white;
  --secondaryColor: rgb(241, 238, 245);
  --tableHeading: rgb(210, 225, 245);
  --tableHighlight: rgb(221, 232, 235);
  --disabled:  #1e1c22;
  --textColor: black;
  --negColor: red;
  --posColor: green;
  --neutralColor: #5614CD;
}


.root {
  height: 100vh;
}

.main {
  background-color: var(--secondaryColor);
}

.App {
  display: flex;
  width: 100%;
}

header {
  display: flex;
  height: 60px;
  width: 100%;
  background-color: var(--primaryColor);
  color: var(--textColor);
  justify-content: space-between;
  position: fixed;
  z-index: 1;
}

header .nav-div {
  display: flex;
  align-items: center;
}

nav {
  padding: 0 2rem;
}

nav a {
  color: var(--textColor);
  text-decoration: none;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
}

.nav-div nav a {
  margin: 0 2rem;
}

nav a:hover {
  background-color: var(--neutralColor);
  color: white;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

.flex {
  display: flex;
}

.hidden_form {
  transform: translateX(-1000px);
  visibility: hidden;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    color: var(--textColor);
    background-color: var(--primaryColor);
    transition: 1s;
    transform: translateY(-200vh);
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  header nav a {
    font-size: 1.5rem;
  }
}

.dashboard {
  max-width: 900px;
  margin: auto;
}

.sidenav {
  width: 300px;
  height: 100vh;
  background-color: var(--primaryColor);
  font-size: 1.2rem;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
}

.sidenav a {
  padding: 5px 5px;
  display: flex;
  align-items: center;
}

.sidenav a svg {
  margin-right: 5px;
}

.sidenav .mainLinks {
  display: flex;
  flex-direction: column;
}

.sidenav .mainLinks a {
  margin: 10px 0;
}

.sidenav nav {
  display: flex;
  flex-direction: column;
}

header .side-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

.add-dash {
  margin-top: 60px;
  background-color: var(--secondaryColor);
  padding: 1rem;
  margin-left: 300px;
  width: 100%;
}

.admin-signIn {
  background-color: white;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
}

.admin-signIn h1,
.admin-signIn h2 {
  text-align: center;
  margin: 1rem;
}

.admin-signIn .sign-in-form form {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  padding: 1rem;
}

.admin-signIn form .input-div {
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  max-width: 500px;
}

.admin-signIn form .input-div input {
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid var(--secondaryColor);
  max-width: 500px;
}

.admin-signIn form .input-div button {
  width: 200px;
  margin: 0 auto;
  font-size: 1.2rem;
}

.list-container {
  margin-top: 60px;
  background-color: var(--secondaryColor);
  padding: 1rem;
  margin-left: 300px;
  width: 100%;
}

.list-container .table-heading{
  background-color: white;
  border-bottom: 1px solid black;
  overflow: hidden;
}

thead {
  overflow: hidden;
}

.list-container .listed-item:hover {
  background-color: #d9c4ff;
}

.add-dash .add-dash-details {
  margin-top: 1rem;
  min-width: 500px;
  background-color: var(--primaryColor);
  padding: 1rem 0;
  border-radius: 10px;
}

.delivery-history {
  width: 300px;
  background-color: var(--primaryColor);
  margin: 1rem;
  min-height: 430px;
}

.add-dash .add-dash-details form {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  padding: 1rem;
}

.add-dash form .input-div {
  margin: 1.5rem auto;
  display: flex;
  flex-direction: column;
  max-width: 500px;
}

.add-dash h2 {
  text-align: center;
  margin-bottom: 1rem;
}


.add-dash form .input-div input {
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid var(--neutralColor);
}

.add-dash form .input-div button {
  width: 200px;
  margin: 0 auto;
  font-size: 1.2rem;
}

.add-dash form .input-div select {
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid var(--neutralColor);
}

.list-container .input-div select {
  margin: 10px 10px;
  padding: 0.3rem;
  border: 1px solid var(--neutralColor);
}


@media only screen and (max-width: 900px) {
  .sidenav {
    transition: 1s;
    transform: translateX(-300px);
  }

  .responsive_side {
    transform: none;
  }

  header .side-btn {
    visibility: visible;
    opacity: 1;
  }

  .add-dash {
    margin: auto;
    margin-top: 60px;
  }

  .list-container {
    margin: auto;
    margin-top: 60px;
  }
}

.profile-name {
  display: flex;
  align-items: center;
}

.list-container table {
  width: 100%;
  border-spacing: 0;
  border-bottom: 1px solid rgb(134, 134, 134);
  font-size: 0.7rem;
}

.list-container table>thead>tr:not(:last-child)>th,
.list-container table>thead>tr:not(:last-child)>td,
.list-container table>tbody>tr:not(:last-child)>th,
.list-container table>tbody>tr:not(:last-child)>td,
.list-container table>tfoot>tr:not(:last-child)>th,
.list-container table>tfoot>tr:not(:last-child)>td,
.list-container table>tr:not(:last-child)>td,
.list-container table>tr:not(:last-child)>th,
.list-container table>thead:not(:last-child),
.list-container table>tbody:not(:last-child),
.list-container table>tfoot:not(:last-child) {
 border-bottom: 1px solid rgb(134, 134, 134);
}

.list-container table tr {
  border-radius: 10px;
}


.list-container td,
th {
  padding: 0.3rem;
}

.list-container th {
  text-align: left;
}


.list-container .listed-item button {
  margin: 3px;
}

.list-container .listed-item button:hover {
  cursor: pointer;
}


.alert-container {
  margin: 10px;
}

.profile-picture {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.5rem;
  overflow: hidden;
}

.profile-picture img {
  width: 2.5rem;
  margin: auto;
  padding: 5px 0;
}

.alert-inner {
  padding: 8px 16px;
  z-index: 1;
  background-color: #84fc94;
  color: rgb(2, 219, 31);
  border-color: rgb(2, 219, 31);
  ;
  text-align: center;
  align-items: center;
}

.error-container {
  margin: 10px;
}

.error-inner {
  padding: 8px 16px;
  margin: auto 0;
  z-index: 1;
  background-color: #ffb0b0;
  color: red;
  border-color: red;
  text-align: center;
  align-items: center;
}

.subMenu {
  padding: 1rem 0;
}

.subMenu .subMenu-heading {
  display: flex;
  align-items: center;
}


.subMenu .subMenu-heading .dropdown-arrow {
  transition: 300ms ease-in-out;
}

.subMenu .subMenu-heading.active .dropdown-arrow {
  transform: rotate(-90deg);
}

.subMenu a {
  width: 100%;
  display: block;
}

.subMenu-heading a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subMenuList {
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--secondaryColor);
  max-height: 0px;
  overflow: hidden;
  transition: max-height 300ms ease-in-out;
  margin-left: 10px;
}

.subMenuList.active {
  max-height: 500px;
}

.subMenuList a {
  padding: 0;
  padding: 5px;
  width: 100%;
  margin: 5px 0;
}

.add-dash form .input-div .item-box input {
  width: 60%;
  margin-right: 1rem;
}

.add-dash form .input-div .item-box .remove-item {
  font-size: 1rem;
}

.add-dash form .input-div .item-box .add-item {
  display: block;
  margin: 0;
  font-size: 1rem;
}

.map-container {
  width: 100%;
  height: 300px;
}

.list-button {
  padding: 3px 2px;
  background: none;
  border-radius: 5px;
}

.red-button {
  color: var(--negColor);
  border: 1px solid var(--negColor);
}

.red-button:hover {
  background: rgba(255, 0, 0, .2);
}

.blue-button {
  color: var(--neutralColor);
  border: 1px solid var(--neutralColor);
}

.disabled-button {
  color: var(--disabled);
  border: 1px solid var(--disabled);
}

.disabled-button:hover {
  cursor: default;
  background-color: rgba(25, 23, 26, 0.2);
}

.disabled-button:disabled {
  cursor: default;
}

.blue-button:hover {
  background-color: rgba(16, 163, 221, .2);
}

.green-button {
  color: var(--posColor);
  border: 1px solid var(--posColor);
}

.green-button:hover {
  background-color: rgb(0, 128, 0, .2);
}

.combobox-popover {
  z-index: 1;
}

.activeLink {
  background-color: var(--neutralColor);
  color: white;
}

.home {
  text-decoration: none;
  color: black;
  font-size: 1.5rem;
  font-weight: 700;
}

.btn {
  padding: 5px;
  border: none;
  background-color: #5614CD;
  color: white;
  border-radius: 15px;
}

.signOut-button {
  padding: 5px;
  border: none;
  background-color: #5614CD;
  color: white;
  border-radius: 10px;
  margin: auto;
}

.signOut-nav {
  padding: 0;
  display: flex;
  align-items: center;
}

.signOut-button:hover {
  cursor: pointer;
}

.btn:hover {
  cursor: pointer;
}

.sign-in-logo {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 1.5rem;
}

.nav-logo {
  max-width: 80px;
  margin: 0 5px;
}

.sign-in-logo img {
  max-width: 500px;
}

.nav-logo img {
  max-width: 80px;
}

.info-dash {
  margin: auto;
  max-width: 820px;
}

/* .delivery-info-cards {
  display: flex;
  flex-wrap: wrap;
} */

.delivery-info-cards {
  border-top: 1px solid rgb(121, 121, 121);
  margin-top: 2rem;
}

.delivery-info-cards h3 {
  margin: 0.5rem 1rem;
  color: rgb(121, 121, 121);
}

.delivery-info-cards .info-card {
  width: 250px;
  height: 100px;
  background-color: var(--primaryColor);
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 2px 2px rgb(223, 222, 222);
  
}
.delivery-info-cards .info-card div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.delivery-info-cards .info-card a {
  margin: 5px;
}

.delivery-info-cards .info-card p {
  font-size: 2rem;
  margin-right: 1rem;
  font-weight: 800;
}

.delivery-info-cards .info-card h4 {
  margin: 5px;
}

.info-dash .top {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  flex-wrap: wrap;
}

.delivery-info-cards .deliveries {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
}


.earnings-container {
  height: fit-content;
}

.earnings {
  width: 300px;
  padding: 10px;
  border-radius: 10px;
  margin: 0 10px;
  background-color: var(--primaryColor);
  box-shadow: 2px 2px rgb(223, 222, 222);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.earnings .value {
  display: flex;
  align-items: center;
}

.earnings span {
  font-size: 3rem;
  font-weight: 600;
  color: var(--posColor);
}

.earnings p {
  font-size: 3rem;
  padding-right: 10px;
}

.middle {
  margin: auto;
  text-align: center;
  width: 100%;
}

.middle svg {
  margin: auto;
}

.barchart {
  background-color: var(--primaryColor);
  border-radius: 10px;
  padding: 0 10px;
  box-shadow: 2px 2px rgb(223, 222, 222);
}